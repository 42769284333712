import React, { useEffect, useState } from "react";
import { app } from "../../../lib/firebase/config";
import {
  DocumentData,
  DocumentReference,
  doc,
  getDoc,
  getFirestore,
  updateDoc,
} from "firebase/firestore";
import { UserCollections, UserTypes } from "../../../types/auth";
import { IFamily, IWorker } from "../../../types/filterFields";
import { StyledImpl2FA } from "./StyledImpl2FA";
import Button from "../../vetrina/components/Button";
import {
  endSignUpWith2FA,
  startSignInWith2FA,
  endSignInWith2FA,
  startSignUpWith2FA,
} from "../../../utils/auth/2FA";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setIsLoading, setMultifactorError } from "../../../store/slices/main";
import { AVAILABLE_PROVINCIE } from "../../../constants/vetrina/Other";
import { ProvinciaItalia } from "../../../constants/provincie";
import { registerUserToSession } from "../../../store/slices/vetrina";
import { RootState } from "../../../store/store";
import { saveSession } from "../../../utils/auth/session";

const initialTitle = "Abilita l'autenticazione a due fattori";
const initialDescription =
  "Per continuare, abilita l'autenticazione a due fattori sul tuo account.";

const Impl2FA = () => {
  const db = getFirestore(app);

  const [title, setTitle] = useState<string>(initialTitle);
  const [description, setDescription] = useState<string>(initialDescription);

  const [errorMessage, setErrorMessage] = useState<string>("");

  const [verificationCode, setVerificationCode] = useState<string>("");

  const [userCollection, setUserCollection] = useState<UserCollections | null>(
    null
  );
  const [userType, setUserType] = useState<UserTypes | null>(null);
  const [user, setUser] = useState<IFamily | IWorker | null>(null);
  const [verificationId, setVerificationId] = useState<any | null>(null);

  const [verificationParams, setVerificationParams] = useState<{
    auth: any;
    user: any;
    multifactorUser: any;
    verificationId: any;
  } | null>(null);

  const urlParams = new URLSearchParams(window.location.search);
  const cf = urlParams.get("cf");
  const isVerifing = urlParams.get("code");
  const from = urlParams.get("from");

  const multiFactorError = useSelector(
    (state: RootState) => state.main.multifactorError
  );

  const externalUserRef = cf && doc(db, "external_users", cf);
  const internalUserRef = cf && doc(db, "internal_users", cf);

  const navigate = useNavigate();
  const dr = useDispatch<any>();

  useEffect(() => {
    const fetchUser = async () => {
      const externalUser = await getUser(externalUserRef);
      const internalUser = await getUser(internalUserRef);

      if (externalUser?.exists()) {
        setUserCollection(UserCollections.EXTERNAL);
        setUserType(externalUser.data().user_type);
      } else if (internalUser?.exists()) {
        setUserCollection(UserCollections.INTERNAL);
        setUserType(internalUser.data().admin ? UserTypes.ADMIN : null);
      }

      const dUser = externalUser?.data() || internalUser?.data() || null;

      setUser(dUser as unknown as IFamily | IWorker);
    };

    fetchUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cf]);

  useEffect(() => {
    if (isVerifing) {
      const start = async () => {
        dr(setIsLoading(true));
        if (!multiFactorError) {
          dr(setIsLoading(false));
          return;
        };
        const { error: resError, verificationId } = await startSignInWith2FA({
          error: multiFactorError,
          buttonId: "start-2fa-btn",
        });

        setVerificationId(verificationId);
        setMultifactorError(resError);
        dr(setIsLoading(false));

      };

      start();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    console.log("missing 2fa on account...");
    if (!multiFactorError && isVerifing) {
      switch (userType) {
        case UserTypes.ADMIN:
          navigate("/sign-in-admin");
          break;
        case UserTypes.DATORE:
          navigate("/sign-in");
          break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [multiFactorError, userType]);

  const startImpl = async () => {
    if (!user || !user.numero_telefono) return;

    const res = await startSignUpWith2FA({
      phoneNumber: user.numero_telefono,
      buttonId: "start-2fa-btn",
    });

    setVerificationParams(res);
    if (Object.values(res).every((v) => v === null)) {
      navigate(userType !== UserTypes.ADMIN ? "/sign-in" : "/sign-in-admin");
    } else {
      navigate(`/r/impl-2fa?cf=${cf}&code=true`);
    }
  };

  const verifyCode = async () => {
    try {
      const userRef = externalUserRef || internalUserRef;

      if (!verificationCode || verificationCode.length < 6) {
        setErrorMessage("Inserisci il codice di verifica");
        return;
      }

      if (from && multiFactorError) {
        const res = await endSignInWith2FA({
          error: multiFactorError,
          verificationId: verificationId,
          verificationCode: verificationCode,
        });

        if (res?.user) {
          navigate(decodeURIComponent(from));
        } else {
          setErrorMessage("Si è verificato un errore, riefettua il login");
        }

        return;
      }
      if (!verificationParams) {
        setErrorMessage("Si è verificato un errore, CAPTCHA non valido");
        return;
      }
      if (
        verificationParams?.auth &&
        verificationParams?.user &&
        verificationParams?.multifactorUser &&
        verificationParams?.verificationId
      ) {
        const { user: authObj, user: currentUser } = await endSignUpWith2FA({
          ...verificationParams,
          verificationCode,
        });

        if (!authObj || !currentUser) {
          setErrorMessage("Codice di verifica errato");
          return;
        }

        if (!userRef) {
          setErrorMessage("Si è verificato un errore");
          return;
        }

        dr(
          registerUserToSession({
            name: user?.name || user?.nome,
            surname: user?.surname || user?.cognome,
            codice_fiscale: user?.codice_fiscale,
            cellulare: user?.numero_telefono,
            email: user?.email,
            provincia: user?.provincia,
            placement_user: user?.placement_user,
          })
        );

        await updateDoc(userRef, {
          multi_factor_auth: true,
        });

        if (userType === UserTypes.ADMIN) {
          navigate("/dashboard");
        }
        if (user?.provincia && userType === UserTypes.DATORE) {
          navigate(
            AVAILABLE_PROVINCIE.includes(user?.provincia as ProvinciaItalia)
              ? `/vetrina?provincia=${user?.provincia}`
              : "/arriving-soon",
            {
              replace: true,
            }
          );
        }
      }
    } catch (e: any) {
      if (e.message.includes("(auth/invalid-verification-code)")) {
        setErrorMessage("Codice di verifica errato");
        return;
      } else {
        setErrorMessage("Si è verificato un errore");
      }
      console.log(e);
    }
  };

  const handleBtn = async () => {
    dr(setIsLoading(true));
    if (!isVerifing) {
      await startImpl();
    } else {
      await verifyCode();
    }
    dr(setIsLoading(false));
  };

  useEffect(() => {
    if (isVerifing) {
      setTitle("Verifica il tuo codice");
      setDescription(
        "Per favore, inserisci il codice che ti abbiamo inviato via SMS."
      );
    }
  }, [isVerifing]);

  return (
    <StyledImpl2FA>
      <div className="main-wrapper">
        <h3 className="dialog-title">{title}</h3>
        <p className="dialog-description">{description}</p>
        <div id="recaptcha-container"></div>
        {isVerifing ? (
          <input
            type="number"
            onChange={(e) => {
              setVerificationCode(e.target.value);
            }}
          />
        ) : null}
        {errorMessage && <p className="error-message">{errorMessage}</p>}
        <Button id="start-2fa-btn" onClick={handleBtn} className="main-btn">
          {isVerifing ? "Verifica" : "Abilita"}
        </Button>
      </div>
    </StyledImpl2FA>
  );
};

const getUser: any = async (
  userRef: "" | DocumentReference<DocumentData> | null
) => {
  if (!userRef) return null;
  return await getDoc(userRef);
};

export default Impl2FA;
