import {
  arrayUnion,
  collection,
  doc,
  DocumentReference,
  Firestore,
  getDoc,
  getDocs,
  getFirestore,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { app } from "../../lib/firebase/config";
import { Auth, getAuth } from "firebase/auth";

export const checkSession = async ({
  codiceFiscale,
  email,
}: {
  codiceFiscale?: string;
  email?: string;
}) => {
  const auth = getAuth(app);
  let cf = codiceFiscale;
  const db = getFirestore(app);

  let haveSession = false;

  let user;

  if (!cf && email) {
    const externalUsers = collection(db, "external_users");
    const internalUsers = collection(db, "internal_users");
    const q_ex = query(externalUsers, where("email", "==", email));
    const q_in = query(internalUsers, where("email", "==", email));
    const externalUser = await getDocs(q_ex);
    const internalUser = await getDocs(q_in);

    user = !externalUser.empty
      ? externalUser.docs[0].data()
      : internalUser.docs[0].data();

    cf = user.codice_fiscale;
  }

  if (!cf) return { haveSession: false };

  if (document.cookie.includes("sessionId")) {
    const sessionId = getCookie("sessionId");
    const docRef = doc(db, "sessions", cf);

    const docSnap = await getDoc(docRef);

    // const docSnap = await getDocs(collection(db, "sessions"));

    const sessions = docSnap.data()?.sessions;
    if (!sessions.includes(sessionId)) {
      auth.signOut();
      document.cookie = `sessionId=; path/; expires=${new Date().toUTCString()}`;

      if (docSnap.data()?.sessions.length === 1) {
        await updateDoc(docRef, {
          sessions: [],
        });
      } else {
        await setDoc(docRef, {
          sessions: [],
        });
      }
    } else {
      haveSession = true;
    }
  }

  return haveSession;
};

export const saveSession = async ({
  codiceFiscale,
  email,
}: {
  codiceFiscale?: string;
  email?: string;
}) => {
  const auth = getAuth(app);
  const db = getFirestore(app);
  const { v4: uuidv4 } = require("uuid");
  const sessionId = uuidv4();

  try {
    let cf = codiceFiscale
    if (!cf && email) cf = (await getCodiceFiscaleByEmail(db, email));

    if (!cf) return;

    const docRef = doc(db, "sessions", cf);
    const existingSessions = await getExistingSessions(docRef);

    if (isSessionInvalid(existingSessions)) {
      await handleInvalidSession(auth, docRef, existingSessions);
    }

    await setDoc(docRef, { sessions: arrayUnion(sessionId) });
    updateSessionCookie(sessionId);
  } catch (error) {
    console.error("Error saving session:", error);
    throw new Error("Failed to save session");
  }
};

async function getCodiceFiscaleByEmail(db: Firestore, email: string) {
  if (!email) return null;

  const externalUsers = collection(db, "external_users");
  const internalUsers = collection(db, "internal_users");
  const q_ex = query(externalUsers, where("email", "==", email));
  const q_in = query(internalUsers, where("email", "==", email));

  const [externalUser, internalUser] = await Promise.all([
    getDocs(q_ex),
    getDocs(q_in),
  ]);

  const user = !externalUser.empty
    ? externalUser.docs[0].data()
    : internalUser.docs[0].data();

  return user?.codice_fiscale || null;
}

async function getExistingSessions(docRef: DocumentReference) {
  const docSnap = await getDoc(docRef);
  return docSnap.exists() ? docSnap.data().sessions : [];
}

function isSessionInvalid(sessions: string[]) {
  const currentSessionId = getCookie("sessionId");
  if (!currentSessionId) return false;
  return !sessions.includes(currentSessionId);
}

async function handleInvalidSession(
  auth: Auth,
  docRef: DocumentReference,
  sessions: string[]
) {
  auth.signOut();
  clearSessionCookie();

  const updateData =
    sessions.length === 1 ? { sessions: [] } : { sessions: [] };
  await updateDoc(docRef, updateData);
}

function updateSessionCookie(sessionId: string) {
  clearSessionCookie();
  document.cookie = `sessionId=${sessionId}; path=/`;
}

function clearSessionCookie() {
  document.cookie = `sessionId=; path=/; expires=${new Date().toUTCString()}`;
}

function getCookie(name: string) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop()?.split(";").shift();
}
